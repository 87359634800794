import React, { useState } from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import classes from "./projects.module.css"
import lmsImage from "../../images/projects/lms.png"
import revenueReportingToolImage from "../../images/projects/revenue-reporting-tool.png"
import eReporterImage from "../../images/projects/ereporter.png"
import { HeaderVariants } from "../../components/header"
import CardsSlider from "../../components/CardsSlider/CardsSlider"

import { Link } from "gatsby"
import tech4devImage from "../../images/websites/tech4dev.png"
import odbirImage from "../../images/websites/ODBIR.png"
import smeupturnImage from "../../images/websites/smeupturn.png"
import ondoWebsiteImage from "../../images/websites/ondo-website.png"
import developOndoImage from "../../images/websites/developondo.png"
import { Button } from "../../components/Button"

const imagesArray = [
  eReporterImage,
  revenueReportingToolImage,
  lmsImage,
  tech4devImage,
  odbirImage,
  smeupturnImage,
  ondoWebsiteImage,
  developOndoImage,
]

const projectsArray = [
  {
    title: "EREPORTER",
    description:
      "Ereporter is an election monitoring and incidence reporting tool that allows citizens at polling units to report election incidences in real-time.",
    link: "/case-studies/ereporter",
  },
  {
    title: "REVENUE REPORTING TOOL",
    description:
      "Revenue Reporting Tool is a revenue management solution that synchronizes all revenues received from Ministries Departments and Agencies of the State, using the entries from all revenue collection platforms.",
    link: "/case-studies/revenue-reporting-tool",
  },
  {
    title: "LEARNING MANAGEMENT TOOL",
    description:
      "Learning Management Solution(LMS) is a Web solution developed for administration, documentation, tracking, reporting, and delivery of educational courses, training programs, and learning and development programs.",
    link: "/case-studies/lms",
  },
  {
    link: "/case-studies/tech4dev",
    title: "TECH4DEV",
    description:
      "We developed a highly interactive website for Tech4Dev, a non-profit organization.",
  },
  {
    link: "/case-studies/odirs",
    title: "ONDO STATE INTERNAL REVENUE SERVICE",
    description:
      "It is a website for Ondo State Internal revenue service, that keeps the public informed on administrative process and enable users to carryout revenue task",
  },
  {
    link: "/case-studies/sme-upturn",
    title: "SMEUPTURN",
    description:
      "We developed an instructional landing page website for SMEUpturn which will help keep visitors informed and collate data from them.",
  },
  {
    link: "/case-studies/ondo-state-website",
    title: "ONDO STATE WEBSITE",
    description: "We built an intuitive website for Ondo state governor.",
  },
  {
    link: "/case-studies/develop-ondo",
    title: "DEVELOP ONDO",
    description: "We built an intuitive website for develop Ondo.",
  },
]

const backgroundColors = [
  "#00B98C",
  "#ED6638",
  "#007981",
  "#263841",
  "#E46D41",
  "#56B6C3",
  "#FF6F1F",
  "#80DED9",
]

const circlePositions = [
  [
    {
      left: "25vh",
      top: "0",
    },
    {
      left: "16vh",
      top: "5vh",
    },
    {
      left: "8vh",
      top: "12vh",
    },
  ],
  [
    {
      top: "48vh",
      left: "-10vh",
    },
    {
      top: "68vh",
      left: "-2vh",
    },
    {
      top: "58vh",
      left: "-7vh",
    },
  ],
  [
    {
      left: "16vh",
      top: "5vh",
    },
    {
      left: "8vh",
      top: "10vh",
    },
    {
      left: "25vh",
      top: "0",
    },
  ],
]

const circleBackgroundColors = [
  "#00B98C",
  "#ED6638",
  "#007981",
  "#263841",
  "#E46D41",
  "#56B6C3",
  "#FF6F1F",
  "#80DED9",
]

function Projects(props) {
  const [active, changeActive] = useState(0)
  return (
    <HomePageLayout pageTitle="projects" headerVariant={HeaderVariants.dark}>
      <SEO title="Projects" />
      <main className={classes.projectsMain}>
        <div className={classes.container}>
          <ul className={classes.projectsList}>
            {projectsArray.map(({ title, description, link }, index) => (
              <li className={classes.projectsListProject} key={index}>
                <button
                  className={[
                    classes.projectsListProjectHeader,
                    active === index
                      ? classes.projectsListProjectHeaderActive
                      : "",
                  ].join(" ")}
                  onClick={() => changeActive(index)}
                >
                  {title}
                </button>
                <p
                  className={[
                    classes.projectsListProjectDescription,
                    active === index
                      ? classes.projectsListProjectDescriptionActive
                      : "",
                  ].join(" ")}
                >
                  {description}
                  {/*<Link to={link} className={classes.projectsListProjectLink}>*/}
                  {/*  CASE STUDY <span />*/}
                  {/*</Link>*/}
                  <Button
                    to={link}
                    className={classes.projectsListProjectButton}
                  >
                    case study
                  </Button>
                </p>
              </li>
            ))}
          </ul>
          <div className={classes.projectsContainer}>
            <span
              className={classes.smallCircle}
              style={{
                ...circlePositions[active % 3][0],
                backgroundColor: circleBackgroundColors[active],
              }}
            />
            <span
              className={classes.smallCircle}
              style={{
                ...circlePositions[active % 3][1],
                backgroundColor: circleBackgroundColors[active],
              }}
            />
            <span
              className={classes.smallCircle}
              style={{
                ...circlePositions[active % 3][2],
                backgroundColor: circleBackgroundColors[active],
              }}
            />
            <div
              className={classes.projectImagesContainer}
              style={{
                backgroundColor: backgroundColors[active],
              }}
            >
              {imagesArray.map((img, i) => (
                <img
                  src={img}
                  alt=""
                  className={[
                    classes.projectImage,
                    active > i ? classes.projectImageLeft : "",
                    active < i ? classes.projectImageRight : "",
                    i === 0 ? classes.projectImage1 : "",
                  ].join(" ")}
                />
              ))}
            </div>
          </div>
          <CardsSlider
            websites={projectsArray}
            websiteImageLinks={imagesArray}
            backgroundColors={backgroundColors}
          />
          <div className={classes.solutionsLinks}>
            <Link to="/solutions/products" className={classes.solutionsLink}>
              PRODUCTS
            </Link>
            {/*<Link to="/solutions/websites" className={classes.solutionsLink}>*/}
            {/*  WEBSITES*/}
            {/*</Link>*/}
          </div>
        </div>
      </main>
    </HomePageLayout>
  )
}

export default Projects
